import toast from "react-hot-toast";
import { setError, setUsers, setLoading } from "./users-reducer";
import { getAuth } from "../authentication/authentication-reducer";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { BASE_URL } from "../../constant";
import axios from "axios";
import _ from "lodash";
import { history } from "../../helpers/browser";
function* handleFetchUsers() {
  try {
    yield put(setLoading(true));
    // const auth = yield select(getAuth);
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${auth.token}`,
    //   },
    // };
    const path = `${BASE_URL}/api/v1/auth/getallusers/`;
    const { data } = yield call([axios, axios.get], path);
    console.log(data)
    let users = [...data.unverifiedusers, ...data.verifiedusers];
    users = _.sortBy(users, "datecreated").reverse();
    yield put(setUsers(users));

    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield call([toast, "error"], "eror");
  }
}

const fetchUsers = (payload) => ({ type: fetchUsers.type, payload });
fetchUsers.type = "USERS/fetchUsers";

function* watchFetchUsers() {
  yield takeEvery(fetchUsers.type, handleFetchUsers);
}
function* handleRejectVerification({ payload: { userid, reason } }) {
  try {
    yield put(setLoading(true));
    const auth = yield select(getAuth);
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const path = `${BASE_URL}/api/v1/auth/rejectidcard/`;
    const body = { userid, reason };
    yield call([axios, axios.post], path, body, config);

    yield put(fetchUsers());
    yield put(setLoading(false));
    toast.success("email sent");
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    yield call([toast, "error"], "eror");
  }
}

const rejectVerification = (payload) => ({
  type: rejectVerification.type,
  payload,
});
rejectVerification.type = "USERS/rejectVerification";

function* watchRejectVerification() {
  yield takeEvery(rejectVerification.type, handleRejectVerification);
}
function* handleApproveVerification({ payload: { userid } }) {
  try {
    yield put(setLoading(true));
    const auth = yield select(getAuth);
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const body = { userid };
    const path = `${BASE_URL}/api/v1/auth/verifyidcard/`;
    yield call([axios, axios.post], path, body, config);
    yield put(fetchUsers());
    yield put(setLoading(false));
    toast.success("Verification approved");
    yield call([history, history?.push], "/users");
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    yield call([toast, "error"], "eror");
  }
}

const approveVerification = (payload) => ({
  type: approveVerification.type,
  payload,
});
approveVerification.type = "USERS/approveVerification";

function* watchApproveVerification() {
  yield takeEvery(approveVerification.type, handleApproveVerification);
}
function* handleCreateNotifications({ payload: { title, message } }) {
  const toastId = toast.loading("sending notifications...");
  try {
    yield put(setLoading(true));
    const path = `https://nqig8ewkfl.execute-api.eu-west-2.amazonaws.com/prod/notifications/sendNotification`;
    const body = { payload: { title, message }, ttl: 0 };
    yield call([axios, axios.post], path, body);
    yield put(setLoading(false));
    toast.success("notifications dispatch complete", {
      id: toastId,
    });
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    toast.error("Error occurred. Please try again", {
      id: toastId,
    });
  }
}

const createNotifications = (payload) => ({
  type: createNotifications.type,
  payload,
});
createNotifications.type = "USERS/createNotifications";

function* watchCreateNotifications() {
  yield takeEvery(createNotifications.type, handleCreateNotifications);
}

export {
  watchFetchUsers,
  fetchUsers,
  rejectVerification,
  watchRejectVerification,
  watchApproveVerification,
  approveVerification,
  createNotifications,
  watchCreateNotifications,
};
