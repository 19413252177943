import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

function CreateNotificationModal({
  onSubmit,
  onChange,
  loading,
  values,
  onClose,
}) {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Notification Title
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="title"
          required
          type="text"
          rows="5"
          placeholder="message"
        />
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Notification Message
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="message"
          required
          type="textarea"
          rows="5"
          placeholder="message"
        />
      </FormGroup>
      {/* <FormGroup>
        <Label className="col-form-label" for="message-text">
          Select a message
        </Label>
        <Input name="messageExample" onChange={onChange} type="select">
          <option value={""}>---Select---</option>
          {messageTypes.map((type) => (
            <option value={type.value}>{type.label.toLocaleLowerCase()}</option>
          ))}
        </Input>
      </FormGroup> */}
    
      <Button color="danger" onClick={onClose}>
        Close
      </Button>
      <Button disabled={loading} color="primary" className="ml-4" type="submit">
        {!loading ? "Submit" : "Submiting"}
      </Button>
    </Form>
  );
}

export default CreateNotificationModal;
