import { combineReducers } from "@reduxjs/toolkit";
import Customizer from "./customizer/reducer";
import {
  reducer as authReducer,
  slice as authSlice,
} from "../features/authentication/authentication-reducer";
import {
  reducer as usersReducer,
  slice as usersSlice,
} from "../features/users/users-reducer";
import {
  reducer as withdrawalRequestsReducer,
  slice as withdrawalRequestsSlice,
} from "../features/withdrawal-requests/withdrawal-requests-reducer";
import {
  reducer as ATCReducer,
  slice as ATCSlice,
} from "../features/atc/atc-reducer";
import {
  reducer as balancesReducer,
  slice as balancesSlice,
} from "../features/balances/balances-reducer";

const rootReducer = combineReducers({
  [authSlice]: authReducer,
  [usersSlice]: usersReducer,
  [withdrawalRequestsSlice]: withdrawalRequestsReducer,
  [ATCSlice]: ATCReducer,
  [balancesSlice]: balancesReducer,
  Customizer,
});

const rootState = rootReducer(undefined, {});
export { rootReducer, rootState };
