import dateFormat from "dateformat";

export const AtcTransactionsColumns = [
  {
    title: "ID",
    field: "id",
  },
  {
    title: "Reference",
    field: "refnum",
  },

  {
    title: "Amount",
    field: "airtimeamount",
    hidden: false,
  },
  {
    title: "Phone Number",
    field: "mobilenumber",
    hidden: false,
  },
  {
    title: "Network",
    field: "network",
  },
  {
    title: "Value given",
    field: "amounttorecieve",
  },

  {
    title: "Currency",
    field: "currency",
  },

  {
    title: "Date",
    field: "createdAt",
    render: (rowData) => (
      <p>{dateFormat(rowData.createdAt, "dS mmmm, yyyy h:MMTT")}</p>
    ),
  },
  {
    title: "Status",
    field: "status",
    // render: (rowData) => (
    //   <div>
    //     {!rowData.approved && (
    //       <div
    //         style={{
    //           borderRadius: "16px",
    //           padding: "4px",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //         className="bg-warning "
    //       >
    //         Not Approved
    //       </div>
    //     )}

    //     {rowData.approved && (
    //       <div
    //         style={{
    //           borderRadius: "16px",
    //           padding: "4px",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //         className="bg-success "
    //       >
    //         Approved
    //       </div>
    //     )}
    //   </div>
    // ),
  },
];
