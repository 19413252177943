import dateFormat from "dateformat";
export const columns = [
  {
    title: "Name",
    field: "name",

    render: (rowData) => (
      <p>
        {rowData?.firstname || "name not"} {rowData?.lastname || "set"}
      </p>
    ),
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Username",
    field: "username",
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "Amount",
    field: "amount",
  },
  {
    title: "Status",
    field: "status",
    render: (rowData) => (
      <div
        style={{
          borderRadius: "16px",
          padding: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={`${
          rowData.status === "pending"
            ? "bg-warning"
            : rowData.status === "processing"
            ? "bg-primary"
            : "bg-success"
        }`}
      >
        {rowData.status}
      </div>
    ),
  },

  {
    title: "Request Date",
    field: "datecreated",
    render: (rowData) => (
      <p>{dateFormat(rowData?.datecreated, "dS mmmm, yyyy h:MMTT")}</p>
    ),
  },
];
