import dateFormat from "dateformat";

export const BalancesColumns = [
  {
    title: "Username",
    field: "username",
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Country",
    field: "country",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "USD",
    field: "usd",
  },
  {
    title: "GHS",
    field: "ghs",
  },
  {
    title: "NGN",
    field: "ngn",
  },
];
