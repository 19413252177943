import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, User, Bell } from "react-feather";
import Bookmark from "../../layout/bookmark";

import {
 
  Notification,
  DeliveryProcessing,
  OrderComplete,
  TicketsGenerated,
  DeliveryComplete,
  CheckAllNotification,
  Admin,
  Account,
  LogOut,
} from "../../constant";

import { signOut } from "../../features/authentication/authentication-saga";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Rightbar = (props) => {
  const signout = useDispatch(signOut);

  const [moonlight, setMoonlight] = useState(false);
  const history = useHistory();
  const [notificationDropDown, setNotificationDropDown] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);
  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };
  //full screen function

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          
          {/* <li><span className="header-search"><Search onClick={() => SeacrhResposive(searchresponsive)} /></span></li> */}
          <li className="onhover-dropdown">
            <div
              className="notification-box"
              onClick={() => setNotificationDropDown(!notificationDropDown)}
            >
              <Bell />
              <span className="badge badge-pill badge-secondary">2</span>
            </div>
            <ul
              className={`notification-dropdown onhover-show-div ${
                notificationDropDown ? "active" : ""
              }`}
            >
              <li>
                <Bell />
                <h6 className="f-18 mb-0">{Notification}</h6>
              </li>
              <li>
                <p>
                  <i className="fa fa-circle-o mr-3 font-primary"> </i>
                  {DeliveryProcessing}{" "}
                  <span className="pull-right">{"10 min."}</span>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-circle-o mr-3 font-success"></i>
                  {OrderComplete}
                  <span className="pull-right">{"1 hr"}</span>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-circle-o mr-3 font-info"></i>
                  {TicketsGenerated}
                  <span className="pull-right">{"3 hr"}</span>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-circle-o mr-3 font-danger"></i>
                  {DeliveryComplete}
                  <span className="pull-right">{"6 hr"}</span>
                </p>
              </li>
              <li>
                <button className="btn btn-primary">
                  {CheckAllNotification}
                </button>
              </li>
            </ul>
          </li>
          <Bookmark />
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li>

          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={man} alt="" />
              <div className="media-body">
                <span>{"Emay Walter"}</span>
                <p className="mb-0 font-roboto">
                  {Admin} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li>
                <User />
                <span>{Account} </span>
              </li>

              <li
                onClick={() => {
                  localStorage.clear();
                  history.push("/login");
                }}
              >
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;
