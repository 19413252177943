import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes";

import { Toaster } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import awsExports from "./aws-exports";
import Amplify from "aws-amplify";
const persistor = persistStore(store);
const ENV = process.env.NODE_ENV;
if (ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,

  });
}
Amplify.configure({
  Logging: {
    logGroupName: "tranzopay-client-app",
    logStreamName: "tranzopay-client-app",
  },
  ...awsExports,
});
const Root = (props) => {
  return (
    <Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <Toaster position="top-right" reverseOrder={true} />
        </PersistGate>
      </Provider>
    </Fragment>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
