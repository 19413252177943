import React, { Fragment } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import Loader from "../../layout/loader";
import WithdrawalRequestsTable from "./withdrawal-requests-table";

function WithdrawalRequestsComponent({
  requests,
  handleApproveRequest,
  loading,
  fetchRequests,
  handleDeclineRequest,
}) {
  return (
    <Fragment>
      <Breadcrumbs parent="Requests" title="Withdrawal Requests" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader></CardHeader>
              <WithdrawalRequestsTable
                handleDeclineRequest={handleDeclineRequest}
                handleApproveRequest={handleApproveRequest}
                data={requests}
                loading={loading}
                fetchRequests={fetchRequests}
              />
            </Card>
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </Fragment>
  );
}

export default WithdrawalRequestsComponent;
