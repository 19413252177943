import {
  Home,
  User,
  ExternalLink,
  Tablet,
  Book,
  BarChart2,
  BarChart
} from "react-feather";
export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",

    Items: [
      {
        title: "Dashboard",
        icon: Home,
        type: "link",
        active: false,
        path: `/dashboard`,
      },
    ],
  },

  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "Users",
        icon: User,
        type: "link",
        active: false,
        path: `/users`,
      },
    ],
  },

  
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "Withdrawal Requests",
        icon: ExternalLink,
        type: "link",
        active: false,
        path: `/withdrawal-requests`,
      },
    ],
  },
  {
    menutitle: "Airtime to cash",
    menucontent: "Airtime to cash",
    Items: [
      {
        title: "ATC Devices",
        icon: Tablet,
        type: "link",
        active: false,
        path: `/atc-devices`,
      },
      {
        title: "ATC Analytics",
        icon:  BarChart,
        type: "link",
        active: false,
        path: `/atc-analytics`,
      },
    ],
  },

  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "Balances",
        icon: Book,
        type: "link",
        active: false,
        path: `/balances`,
      },
    ],
  },
  {
    menutitle: "",
    menucontent: "",
    Items: [
      {
        title: "Transactions",
        icon: BarChart2,
        type: "link",
        active: false,
        path: `/transactions`,
      },
    ],
  },
];
