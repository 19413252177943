import React from "react";
import { forwardRef } from "react";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { columns } from "./withdrawal-requests-data";
import { Chip, createTheme, MuiThemeProvider } from "@material-ui/core";
import { Check, RefreshCcw } from "react-feather";
import { toast } from "react-toastify";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),

  Approve: forwardRef((props, ref) => (
    <Chip {...props} ref={ref} label="Approve" color={"primary"} />
  )),
  Decline: forwardRef((props, ref) => (
    <Chip {...props} ref={ref} label="Decline" color="secondary" />
  )),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function WithdrawalRequestsTable({
  data,
  handleApproveRequest,
  loading,
  fetchRequests,
  handleDeclineRequest,
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#274ffe",
      },
      secondary: {
        main: "#26facb",
      },
    },
  });
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />

              <div
                className=""
                style={{
                  padding: "10px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <RefreshCcw /> */}
                <Chip
                  onClick={() => fetchRequests()}
                  label={"Refresh"}
                  color={"primary"}
                  style={{ marginRight: 5 }}
                />
              </div>
            </div>
          ),
        }}
        data={data}
        icons={tableIcons}
        columns={columns}
        title={<p className="font-bold">{`${data?.length} requests`}</p>}
        options={{
          search: true,
          //   selection: false,
          exportButton: true,
          actionsColumnIndex: -1,
          //   pageSize: 20,
          rowStyle: {
            backgroundColor: "#fff",
          },
          headerStyle: {
            backgroundColor: "#F4F4F4",
            color: "#666666",
            border: 0,
            fontWeight: "600",
          },
        }}
        // localization={{
        //   toolbar: { nRowsSelected: "{0} Card selected" },
        // }}

        actions={[
          {
            icon: tableIcons.Approve,
            tooltip: "Approve Withdrawal",
            onClick: (event, rowData) => {
              if (rowData.status === "processing") {
                toast.error(
                  "Withdrawal request has already been initiated and is processing."
                );
                return;
              }
              handleApproveRequest(rowData.transid);
            },
          },
          {
            icon: tableIcons.Decline,
            tooltip: "Decline Withdrawal",
            onClick: (event, rowData) => handleDeclineRequest(rowData.transid),
          },
        ]}
      />
    </MuiThemeProvider>
  );
}

export default WithdrawalRequestsTable;
