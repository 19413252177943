import React, { useEffect, useState } from "react";
import DashboardComponent from "./dashboard-component";
import { compose } from "ramda";
import { connect } from "react-redux";
import { getLoading, getUsers } from "../users/users-reducer";
import { fetchUsers } from "../users/users-saga";
import { fetchRequests } from "../withdrawal-requests/withdrawal-requests-saga";
import _ from "lodash";
import { standardizeDate } from "../../helpers/date";
import { getRequests } from "../withdrawal-requests/withdrawal-requests-reducer";
import { fetchTransactions } from "../balances/balances-saga";
import { getTransactions } from "../balances/balances-reducer";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  users: getUsers(state),
  withdrawalRequests: getRequests(state),
  transactions: getTransactions(state),
});

const DashboardContainer = compose(
  connect(mapStateToProps, {
    fetchUsers,
    fetchRequests,
    fetchTransactions,
  })
)(({ loading, fetchUsers, users, fetchRequests, withdrawalRequests,fetchTransactions,transactions }) => {
  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    centerPadding: "5px",
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [signups, setSignups] = useState([{ name: "Users", data: [] }]);
  // [{
  //   name: 'Inflation',
  //   data: [2.3, 5.1, 3.0, 9.1, 2.0, 4.6, 2.2, 9.3, 5.4, 4.8, 3.5, 5.2]
  // }]
  useEffect(() => {
    fetchUsers();
    fetchRequests();
    fetchTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let allusers = users;
    //group signups by date
    let c = [];
    for (var i = 0; i < users.length; i++) {
      const datecreated = standardizeDate(allusers[i].datecreated);
      const monthNumber = parseInt(datecreated.substring(5, 7));
      const yearNumber = parseInt(datecreated.substring(0, 4));
      const currenctYear = new Date().getFullYear();
      if (currenctYear === yearNumber) {
        c.push({ ...allusers[i], month: monthNumber });
      }
    }
    var months = _.mapValues(_.groupBy(c, "month"), (list) =>
      list.map((item) => _.omit(item, "month"))
    );
    let items = [];
    for (const [key, value] of Object.entries(months)) {
      items.push({
        month: parseInt(key),
        total: value.length,
      });
    }
    let k = [];
    for (var j = 0; j < items.length; j++) {
      k[j] = items[j].total;
    }
    setSignups([
      {
        name: "Users",
        data: k,
      },
    ]);
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardComponent
      withdrawalRequests={withdrawalRequests}
      users={users}
      signups={signups}
      settings={settings}
      transactions={transactions}
    />
  );
});

export default DashboardContainer;
