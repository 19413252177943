import dateFormat from "dateformat";

export const DevicesColumns = [
  {
    title: "Device ID",
    field: "imei",
  },

  {
    title: "Owner Name",
    field: "ownerName",
    hidden: true,
  },
  {
    title: "Owner Email",
    field: "ownerEmail",
    hidden: true,
  },
  {
    title: "Phone Number",
    field: "phoneNumber",
  },
  {
    title: "Country",
    field: "country",
  },
//   {
//     title: "Network",
//     field: "network",
//   },
  {
    title: "Date Added",
    field: "createdAt",
    render: (rowData) => (
      <p>{dateFormat(rowData.createdAt, "dS mmmm, yyyy h:MMTT")}</p>
    ),
  },
  {
    title: "Approved",
    field: "approved",
    render: (rowData) => (
      <div>
        {!rowData.approved && (
          <div
            style={{
              borderRadius: "16px",
              padding: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-warning "
          >
            Not Approved
          </div>
        )}

        {rowData.approved && (
          <div
            style={{
              borderRadius: "16px",
              padding: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-success "
          >
            Approved
          </div>
        )}
      </div>
    ),
  },
];
