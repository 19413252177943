import dateFormat from "dateformat";
export const usersTableColumns = [
  {
    title: "ID",
    field: "id",
    filtering: false,
  },
  {
    title: "Name",
    field: "name",
    filtering: false,
    render: (rowData) => (
      <p>
        {rowData.firstname || "name not"} {rowData.lastname || "set"}
      </p>
    ),
  },
  {
    title: "Email",
    field: "email",
    filtering: false,
  },
  {
    title: "Username",
    field: "username",
    filtering: false,
  },
  {
    title: "Phone",
    field: "phone",
    filtering: false,
  },
  {
    title: "Country",
    field: "country",
    filtering: false,
  },
  {
    title: "Verification Status",
    field: "is_id_verified",
    filtering: false,
    render: (rowData) => (
      <div>
        {rowData.is_id_set && !rowData.is_id_verified && (
          <div
            style={{
              borderRadius: "16px",
              padding: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-warning "
          >
            pending
          </div>
        )}
        {!rowData.is_id_set && !rowData.is_id_verified && (
          <div
            style={{
              borderRadius: "16px",
              padding: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-danger "
          >
            No ID
          </div>
        )}
        {rowData.is_id_set && rowData.is_id_verified && (
          <div
            style={{
              borderRadius: "16px",
              padding: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-success "
          >
            approved
          </div>
        )}

        {/* <p>
        {rowData.is_id_verified
          ? "User has been verified"
          : "not verified"}
      </p> */}
      </div>
    ),
  },
  {
    title: "Date Joined",
    field: "datecreated",
    filtering: false,
    render: (rowData) => (
      <p>{dateFormat(rowData.datecreated, "dS mmmm, yyyy h:MMTT")}</p>
    ),
  },
];

export const WaitListTableColumns = [
  {
    title: "Fullname",
    field: "fullname",
  },

  {
    title: "Email",
    field: "email",
  },
];

export const UserBalancesColumns = [
  {
    title: "Username",
    field: "username",
  },

  {
    title: "Country",
    field: "country",
  },
  {
    title: "Phone",
    field: "phone",
  },
  {
    title: "GHS",
    field: "ghs",
  },
  {
    title: "USD",
    field: "usd",
  },
  {
    title: "NGN",
    field: "ngn",
  },
];
