import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getLoading, getUsers } from "../users-reducer";
import UserDetailsComponent from "./user-details-component";
import {
  fetchUsers,
  approveVerification,
  rejectVerification,
} from "../users-saga";
import { compose } from "ramda";
import { connect } from "react-redux";
import { BASE_URL } from "../../../constant";
import axios from "axios";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  users: getUsers(state),
});

const UserDetailsContainer = compose(
  connect(mapStateToProps, {
    fetchUsers,
    approveVerification,
    rejectVerification,
  })
)(
  ({
    loading,
    fetchUsers,
    error,
    users,
    approveVerification,
    rejectVerification,
  }) => {
    const { id } = useParams();
    const [userDetails, setUserDetails] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const history = useHistory();
    const [values, setValues] = useState({ message: "We have reviewed the documents you submitted for verification, but they are not valid. Please make sure to upload the correct documents so we can verify your account.", messageExample: "" });
    const [images, setImages] = useState([]);
    const onChange = (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    };
    const handleShowModal = () => {
      setShowModal(true);
    };
    const handleCloseModal = () => {
      setShowModal(false);
    };
    useEffect(() => {
      fetchDocuments()
    }, []);
    const fetchDocuments = async () => {
      try {
        const u = users.find((c) => c.id === parseInt(id));
  
        const { data } = await axios.post(
          `${BASE_URL}/api/v1/auth/getdocumentdetails`,
          { username: u.username }
        );
        setImages([`${BASE_URL}${data.doc_url}`, `${BASE_URL}${data.selfie_url}`]);
        
      } catch (error) {
        console.log(error.response.data)
      }
    };
    const onSubmitRejection = (e) => {
      e.preventDefault();
      rejectVerification({ userid: id, reason: values.message });
      setValues({ message: "" });
      handleCloseModal();
    };
    useEffect(() => {
      const u = users.find((c) => c.id === parseInt(id));
      setUserDetails(u);
      

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log(images,"here");

    return (
      <UserDetailsComponent
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleCloseModal={handleCloseModal}
        history={history}
        userDetails={userDetails}
        onChange={onChange}
        values={values}
        approveVerification={approveVerification}
        rejectVerification={rejectVerification}
        onSubmitRejection={onSubmitRejection}
        loading={loading}
        id={id}
        isOpen={isOpen}
        images={images}
        setIsOpen={setIsOpen}
        photoIndex={photoIndex}
        
        setPhotoIndex={setPhotoIndex}
      />
    );
  }
);
export default UserDetailsContainer;
