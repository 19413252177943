import { all } from "redux-saga/effects";
import {
  watchAddDevice,
  watchApproveDevice,
  watchDeleteDevice,
  watchFetchDevices,
} from "../features/atc/atc-saga";
import {
  watchLogin,
  watchSignOut,
} from "../features/authentication/authentication-saga";
import { watchFetchBalances, watchFetchTransactions } from "../features/balances/balances-saga";
import {
  watchApproveVerification,
  watchCreateNotifications,
  watchFetchUsers,
  watchRejectVerification,
} from "../features/users/users-saga";
import {
  watchApproveRequest,
  watchDeclineRequest,
  watchFetchRequests,
} from "../features/withdrawal-requests/withdrawal-requests-saga";

function* rootSaga() {
  yield all([
    watchLogin(),
    watchSignOut(),
    watchFetchUsers(),
    watchRejectVerification(),
    watchApproveVerification(),
    watchFetchRequests(),
    watchApproveRequest(),
    watchCreateNotifications(),
    watchDeclineRequest(),
    watchFetchDevices(),
    watchApproveDevice(),
    watchAddDevice(),
    watchDeleteDevice(),
    watchFetchBalances(),
    watchFetchTransactions()
  ]);
}

export { rootSaga };
