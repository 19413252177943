import React from "react";
import { forwardRef } from "react";
import MaterialTable, { MTableToolbar, Expo } from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  usersTableColumns,
  WaitListTableColumns,
  UserBalancesColumns,
} from "./users-data";
import { Chip, createTheme, MuiThemeProvider } from "@material-ui/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),

  //   Check: forwardRef((props, ref) => <CheckBox {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function UsersTable({
  data,
  handleRowClick,
  filters,
  handleClickFilter,
  filterTerm,
  handleShowModal,
  fetchUsers,
  tableType,
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#274ffe",
      },
      secondary: {
        main: "#26facb",
      },
    },
  });
  const returnColumnType = () => {
    let colType = usersTableColumns;
    switch (tableType) {
      case "Users":
        colType = usersTableColumns;

        break;
      case "Waitlist Users":
        colType = WaitListTableColumns;

        break;
      case "Balances":
        colType = UserBalancesColumns;

        break;

      default:
        break;
    }
    return colType;
  };
  const getPageSize = (data) => {
    const length = data.length;
    const pageSizes = [20, 50, 100, 500, 800, data.length];
    let sizes = [5];
    for (let i = 0; i < pageSizes.length; i++) {
      sizes.push(pageSizes[i]);
    }
    return sizes;
  };
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        components={{
          Toolbar: (props) =>
            tableType === "Users" ? (
              <div>
                <MTableToolbar {...props} />

                <div style={{ padding: "10px 10px" }}>
                  <p>Filter verification status By:</p>
                  {filters.map((filter) => (
                    <Chip
                      onClick={() => handleClickFilter(filter)}
                      label={filter}
                      color={
                        filterTerm === filter.toLowerCase()
                          ? "primary"
                          : "default"
                      }
                      style={{ marginRight: 5 }}
                    />
                  ))}
                  <Chip
                    onClick={fetchUsers}
                    label={"Refresh"}
                    color="secondary"
                    style={{ marginLeft: 40 }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <MTableToolbar {...props} />
              </div>
            ),
          Action: (props) => (
            <button
              onClick={(event) => props.action.onClick(event, props.data)}
              style={{ textTransform: "none" }}
              className="btn btn-primary ml-4"
            >
              Create notification
            </button>
          ),
        }}
        data={data}
        icons={tableIcons}
        columns={[...returnColumnType()]}
        title={<p className="font-bold">{`${data?.length} users`}</p>}
        options={{
          search: true,
          selection: false,
          pageSize: Math.min(5, 25),
          columnsButton: false,
          pageSizeOptions: getPageSize(data),
          rowStyle: {
            backgroundColor: "#fff",
          },
          headerStyle: {
            backgroundColor: "#F4F4F4",
            color: "#666666",
            border: 0,
            fontWeight: "600",
          },
          exportMenu: [
            {
              label: "Export PDF",
              //// You can do whatever you wish in this function. We provide the
              //// raw table columns and table data for you to modify, if needed.
              // exportFunc: (cols, datas) => console.log({ cols, datas })
              exportFunc: (cols, datas) => ExportPdf(cols, datas, "dataPDF"),
            },
            {
              label: "Export CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "dataCSV"),
            },
          ],
        }}
        actions={[
          {
            icon: tableIcons.Add,
            tooltip: "Add User",
            isFreeAction: true,
            onClick: handleShowModal,
          },
        ]}
        onRowClick={handleRowClick}
        localization={{
          toolbar: { nRowsSelected: "{0} Card selected" },
        }}
      />
    </MuiThemeProvider>
  );
}

export default UsersTable;
