import React, { Fragment } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import ModalBox from "../../../components/modal/modal";
import Breadcrumb from "../../../layout/breadcrumb";
import Loader from "../../../layout/loader";
import AddDeviceModal from "./add-device-modal";
// import CreateNotificationModal from "./create-notification-modal";
import DevicesTable from "./devices-table";
function UsersComponent({
  devices,
  loading,
  onChange,
  handleCloseModal,
  values,
  onSubmit,
  showModal,
  handleShowModal,
  approveDevice,
  deleteDevice
}) {
  return (
    <Fragment>
      <Breadcrumb parent="Devices" title="All Devices" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <DevicesTable
                approveDevice={approveDevice}
                data={devices}
                handleShowModal={handleShowModal}
                deleteDevice={deleteDevice}
              />
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalBox
        open={showModal}
        content={
          <AddDeviceModal
            onChange={onChange}
            onClose={handleCloseModal}
            values={values}
            onSubmit={onSubmit}
            loading={loading}
          />
        }
      />
      <Loader show={loading} />
    </Fragment>
  );
}

export default UsersComponent;
