import { Chip } from "@material-ui/core";
import React, { Fragment } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import ModalBox from "../../components/modal/modal";
import Breadcrumb from "../../layout/breadcrumb";
import Loader from "../../layout/loader";
import CreateNotificationModal from "./create-notification-modal";
import UsersTable from "./users-table";
function UsersComponent({
  users,
  loading,
  handleRowClick,
  filters,
  handleClickFilter,
  filterTerm,
  showModal,
  handleShowModal,
  handleCloseModal,
  values,
  onChange,
  onSubmitCreateNotification,
  fetchUsers,
  tableTypes,
  tableType,
  handleSelectTableType,
  wailist,
  balances,
}) {
  const returnTableType = () => {
    let data = users;
    switch (tableType) {
      case "Users":
        data = users;

        break;
      case "Waitlist Users":
        data = wailist;

        break;
      case "Balances":
        data = balances;

        break;

      default:
        break;
    }
    return data;
  };
  return (
    <Fragment>
      <Breadcrumb parent="Users" title="All Users" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader>
                <div style={{ display: "flex" }}>
                  {tableTypes.map((item) => (
                    <Chip
                      label={item}
                      color={tableType === item ? "primary" : "default"}
                      onClick={() => handleSelectTableType(item)}
                    />
                  ))}
                </div>
              </CardHeader> */}
              {/* <CardBody> */}
              <UsersTable
                filters={filters}
                handleClickFilter={handleClickFilter}
                handleRowClick={handleRowClick}
                data={[...users]}
                filterTerm={filterTerm}
                handleShowModal={handleShowModal}
                fetchUsers={fetchUsers}
                tableType={tableType}
              />
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalBox
        open={showModal}
        content={
          <CreateNotificationModal
            onChange={onChange}
            onClose={handleCloseModal}
            values={values}
            onSubmit={onSubmitCreateNotification}
            loading={loading}
          />
        }
      />
      <Loader show={loading} />
    </Fragment>
  );
}

export default UsersComponent;
