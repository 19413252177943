import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constant";
import AtcAnalyticsComponent from "./atc-analytics-component";

function AtcAnalyticsContainer() {
  const [totalAtc, setTotalAtc] = useState({ airtel_amount: 0, mtn_amount: 0 });
  const [profit, setProfit] = useState({
    mtn_profit: 0,
    airtel_profit: 0,
  });
  const [transactions, setTransactions] = useState([]);
  const fetchTotalTransaction = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/getTotalTransaction/`
      );
      setTotalAtc(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllTransactions = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/v1/getNetworkTransactions/`
      );
      setTransactions(data.transactions);
      console.log(data);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const fetchProfit = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/v1/getNetworkProfit/`);
      setProfit(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTotalTransaction();
    fetchProfit();
    fetchAllTransactions();
  }, []);

  return (
    <AtcAnalyticsComponent
      profit={profit}
      totalAtc={totalAtc}
      transactions={transactions}
    />
  );
}

export default AtcAnalyticsContainer;
