import React, { useEffect, useState } from "react";
import BalancesComponent from "./balances-component";

import { fetchBalances } from "./balances-saga";
import { compose } from "ramda";
import { connect } from "react-redux";
import { getLoading, getBalances } from "./balances-reducer";
import { useHistory } from "react-router-dom";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  balances: getBalances(state),
});

const BalancesContainer = compose(
  connect(mapStateToProps, {
    fetchBalances,
  })
)(({ loading, fetchBalances, balances }) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchBalances();
  }, []);

  return (
    <BalancesComponent
      loading={loading}
      showModal={showModal}
      balances={balances}
      handleShowModal={handleShowModal}
      handleCloseModal={handleCloseModal}
    />
  );
});

export default BalancesContainer;
