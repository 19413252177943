import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";
import Loader from "../../../layout/loader";
import TransactionsTable from "./transactions-table";
function TransactionsComponent({ transactions, loading }) {
  return (
    <Fragment>
      <Breadcrumb parent="Balances" title="All Balances" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <TransactionsTable data={transactions} />

            </Card>
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </Fragment>
  );
}

export default TransactionsComponent;
