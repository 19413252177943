import toast from "react-hot-toast";
import {
  setError,
  setRequests,
  setLoading,
} from "./withdrawal-requests-reducer";
import { getAuth } from "../authentication/authentication-reducer";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { BASE_URL } from "../../constant";

import axios from "axios";
import _ from "lodash";
// const BASE_URL = "https://tranzopay.pythonanywhere.com";
function* handleFetcRequests() {
  try {
    yield put(setLoading(true));
    const auth = yield select(getAuth);
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const path = `${BASE_URL}/api/v1/getallmanualWithdrawals/`;
    const { data } = yield call([axios, axios.get], path);
    let w = data.withdrawals;
    w = _.sortBy(w, "datecreated").reverse();
    yield put(setRequests(w));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response, "here");
    yield call([toast, "error"], "eror");
  }
}

const fetchRequests = (payload) => ({ type: fetchRequests.type, payload });
fetchRequests.type = "WITHDRAWALREQUESTS/fetchRequests";

function* watchFetchRequests() {
  yield takeEvery(fetchRequests.type, handleFetcRequests);
}
function* handleApproveRequest({ payload: { id } }) {
  try {
    yield put(setLoading(true));
    const auth = yield select(getAuth);
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const path = `${BASE_URL}/api/v1/updateTransaction/${id}/`;
    yield call([axios, axios.post], path, {}, config);
    yield put(fetchRequests());
    yield put(setLoading(false));
    yield call([toast, "success"], "Successfully approved");
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    yield call([toast, "error"], "eror");
  }
}

const approveRequest = (payload) => ({
  type: approveRequest.type,
  payload,
});
approveRequest.type = "USERS/approveRequest";

function* watchApproveRequest() {
  yield takeEvery(approveRequest.type, handleApproveRequest);
}
function* handleDeclineRequest({ payload: { id } }) {
  try {
    yield put(setLoading(true));
    const auth = yield select(getAuth);
    const config = {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    };
    const path = `${BASE_URL}/api/v1/declinewithdrawal/${id}/`;
    yield call([axios, axios.get], path, config);
    yield put(fetchRequests());
    yield put(setLoading(false));
    yield call([toast, "success"], "Successfully rejected");
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    yield call([toast, "error"], "eror");
  }
}

const declineRequest = (payload) => ({
  type: declineRequest.type,
  payload,
});
declineRequest.type = "USERS/declineRequest";

function* watchDeclineRequest() {
  yield takeEvery(declineRequest.type, handleDeclineRequest);
}

export {
  approveRequest,
  watchApproveRequest,
  fetchRequests,
  watchFetchRequests,
  declineRequest,
  watchDeclineRequest,
};
