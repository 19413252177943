/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */


import { ChevronLeft } from "react-feather";
import { withRouter } from "react-router-dom";

const ErrorBoundary = ({ onClick }) => {
  return ( 
    <div className="mt-8  ">
      <div className="flex flex-col bg-primary-200 px-4">
        <div className="flex px-5 flex-col py-10 h-5/6 bg-white rounded-l-16 items-center rounded-r-16 rounded-bl-16 rounded-br border border-primary-400 ">
          <div className="w-full mb-5">
            <div className="flex gap-3 items-center">
              <button onClick={onClick}>
                <ChevronLeft className="h-6" />
              </button>
            </div>
          </div>
          <div className="flex flex-col md:w-5/12">
            <div className="flex flex-col items-center">
              {/* <img src={Co} alt="" width={200} /> */}
              <p className="md:text-xl leading-10  font-serif mt-4 text-3xl text-center md:text-left text-transparent bg-clip-text font-extrabold bg-gradient-to-l from-secondary-900 to-secondary-800">
                Something went wrong
              </p>
              <p className="text-center my-5">
                There was a problem loading this page. Please refresh, or
                contact support@tranzopay.com if this problem persists
              </p>
            </div>
            <div className="">
              {/* <Button
                size="big"
                className="outlineLg w-full rounded-5 mt-2"
                color={"dark"}
                type="button"
                onClick={onClick}
                // disabled={loading ? true : false}
              >
                <p className="text-white text-base font-bold">Refresh</p>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ErrorBoundary);
