// dashbaord
import Dashboard from "../features/dashboard/dasboard-container";
import Users from "../features/users/users-container";
import UsersDetails from "../features/users/user-details/user-details-container";
import WithdrawalRequests from "../features/withdrawal-requests/withdrawal-requests-container";
import ATCDevices from "../features/atc/devices/devices-container";
import Balances from "../features/balances/balances-container";
import Transactions from "../features/balances/transactions/transactions-container";
import AtcAnalytics from "../features/atc/analytics/atc-analytics-container";
export const routes = [
  { path: `/dashboard`, Component: Dashboard },
  { path: `/users`, Component: Users },
  { path: `/user/:id`, Component: UsersDetails },
  { path: `/withdrawal-requests`, Component: WithdrawalRequests },
  { path: `/atc-devices`, Component: ATCDevices },
  { path: `/balances`, Component: Balances },
  { path: `/transactions`, Component: Transactions },
  { path: `/atc-analytics`, Component: AtcAnalytics },

  // { path:`${process.env.PUBLIC_URL}/starter-kits/sample-page/:layout`, Component:Starterkits},
];
