// import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from 'redux-saga'
// import reducers from '../redux/index';
// const sagaMiddleware = createSagaMiddleware()

// const store = createStore(
//     reducers,
//     compose(
//       applyMiddleware(sagaMiddleware),
//       window.devToolsExtension
//         ? window.devToolsExtension()
//         : function (f) {
//             return f;
//           }
//     )
//   );

// export default store;

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./root-reducer";
import { rootSaga } from "./root-saga";
import storage from "./storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth","withdrawalRequests"],
};

const middleware = getDefaultMiddleware({
  thunk: true,
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
});

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);

  // const logger = createLogger({});

  // middleware.push(logger);
}

const sagaMiddleWare = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [...middleware, sagaMiddleWare],
});

sagaMiddleWare.run(rootSaga);

export default store;
