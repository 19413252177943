import { Auth } from "aws-amplify";

import { history } from "../../helpers/browser";
import toast from "react-hot-toast";
import { clear } from "../../redux/clear";
import { call, put, takeEvery } from "redux-saga/effects";

import { setAuth, setError, setLoading } from "./authentication-reducer";
import { BASE_URL } from "../../constant";
import axios from "axios";

function* handleLogin({ payload: { email, password } }) {
  try {
    yield put(setLoading(true));
    yield put(setError(""));
    //   const user=yield call([Auth, "signIn"], {
    //   username: "tranzopayAdmin",
    //   password: "password0345432",
    // });
    const path = `${BASE_URL}/api/v1/auth/loginadmin/`;
    const body = { email, password };
    const { data } = yield call([axios, axios.post], path, body);
    yield put(setAuth(data));
    yield put(setLoading(false));
    yield call([history, history?.push], "/dashboard");
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield call([toast, "error"], "invalid credentials");
  }
}

const login = (payload) => ({ type: login.type, payload });
login.type = "AUTHENTICATION/login";

function* watchLogin() {
  yield takeEvery(login.type, handleLogin);
}

function* handleSignOut() {
  try {
    yield call([Auth, signOut]);
    yield put(clear());
  } catch (error) {
    console.log(error);
  }
}

const signOut = (payload) => ({ type: signOut.type, payload });
signOut.type = "AUTHENTICATION/signOut";

function* watchSignOut() {
  yield takeEvery(signOut.type, handleSignOut);
}

export { watchLogin, watchSignOut, signOut, login, handleSignOut, handleLogin };
