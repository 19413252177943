import React from "react";
import { Fragment } from "react";
import { ArrowLeft } from "react-feather";
import { Button, Card, Col, Container, Media, Row } from "reactstrap";
import avartar from "../../../assets/images/avtar/3.jpg";
import ModalBox from "../../../components/modal/modal";
import { BASE_URL } from "../../../constant";
import RejectVerificationModal from "./reject-verification-modal";
import Breadcrumb from "../../../layout/breadcrumb";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
function UserDetailsComponent({
  userDetails,
  history,
  showModal,
  handleShowModal,
  handleCloseModal,
  values,
  onChange,
  approveVerification,
  onSubmitRejection,
  loading,
  id,
  isOpen,
  images,
  setIsOpen,
  photoIndex,
  setPhotoIndex,
}) {
  const { is_id_set, is_id_verified, firstname, lastname } = userDetails;
  return (
    <Fragment>
      <Breadcrumb parent="Users" title="User Profile" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Button onClick={() => history.goBack()} color="" size="">
                <ArrowLeft />
              </Button>

              <Card>
                <div className="profile-img-style">
                  <Row>
                    <Col sm="8">
                      <div className="media">
                        {userDetails.photo_url ? (
                          <Media
                            className="img-thumbnail rounded-circle mr-3"
                            src={`${BASE_URL}${userDetails.photo_url}`}
                            alt="Generic placeholder image"
                          />
                        ) : (
                          <Media
                            className="img-thumbnail rounded-circle mr-3"
                            src={avartar}
                            alt="Generic placeholder image"
                          />
                        )}
                        <div className="media-body align-self-center">
                          <h5 className="mt-0 user-name">
                            {userDetails?.username}
                          </h5>
                          <p>
                            {firstname} {lastname}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="align-self-center">
                      <div className="float-sm-right">
                        {is_id_verified ? (
                          <div
                            className="bg-success px-4"
                            style={{ borderRadius: "10px" }}
                          >
                            Verified
                          </div>
                        ) : (
                          <div
                            className="bg-danger px-4"
                            style={{ borderRadius: "10px" }}
                          >
                            Not Verified
                          </div>
                        )}
                        {/* <small>
                          {is_id_verified ? "Verified" : "Not Verified"}
                        </small> */}
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="12" xl="7">
                      <div
                        id="aniimated-thumbnials-3"
                        style={{ width: "100%" }}
                      >
                        <p
                          onClick={() => setIsOpen(true)}
                          className=""
                          style={{ color: "blue" }}
                        >
                          Click the image to expand
                        </p>
                        <a href="#javascript">
                          {images.length > 0 ? (
                            <div
                              style={{
                                // display: "flex",
                                width: "100%",
                                // justifyContent: "space-between",
                              }}
                              onClick={() => setIsOpen(true)}
                            >
                              {images.map((item) => (
                                <img
                                  body
                                  className="img-fluid rounded"
                                  src={item}
                                  alt="gallery"
                                  height={300}
                                  width={300}
                                />
                              ))}
                            </div>
                          ) : (
                            <p>No document submitted</p>
                          )}
                        </a>
                      </div>

                      <div
                        className="like-comment mt-4 like-comment-lg-mb"
                        style={{ width: "100%" }}
                      >
                        <ul className="list-inline" style={{ width: "100%" }}>
                          <li className="list-inline-item border-right pr-3">
                            <Button
                              onClick={handleShowModal}
                              color="danger"
                              size="lg"
                            >
                              Reject verification
                            </Button>
                          </li>
                          <li className="list-inline-item ml-2">
                            <Button
                              onClick={() =>
                                approveVerification({ userid: id })
                              }
                              disabled={
                                !is_id_verified && !is_id_set ? true : false
                              }
                              color="success"
                              size="lg"
                            >
                              {loading ? "Approving" : " Approve verification"}
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <ModalBox
        open={showModal}
        content={
          <RejectVerificationModal
            onChange={onChange}
            onClose={handleCloseModal}
            values={values}
            onSubmit={onSubmitRejection}
            loading={loading}
            // rejectVerification={rejectVerification}
          />
        }
      />
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Fragment>
  );
}

export default UserDetailsComponent;
