import dateFormat from "dateformat";

export const BalancesColumns = [
  {
    title: "Username",
    field: "username",
  },
  {
    title: "Name",
    field: "name",
  },
 
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Type",
    field: "transaction_type",
  },
  {
    title: "Status",
    field: "status",
  },
  
  {
    title: "Balance before",
    field: "user_previous_balance",
  },
  
  {
    title: "Balance after",
    field: "balance_after",
  },
  
  {
    title: "Amount",
    field: "amount",
  },
  
];
