import React, { useEffect, useState } from "react";
import DevicesComponent from "./devices-component";

import {
  fetchDevices,
  approveDevice,
  addDevice,
  deleteDevice,
} from "../atc-saga";
import { compose } from "ramda";
import { connect } from "react-redux";
import { getLoading, getDevices } from "../atc-reducer";
import { useHistory } from "react-router-dom";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  devices: getDevices(state),
});

const DevicesContainer = compose(
  connect(mapStateToProps, {
    fetchDevices,
    addDevice,
    approveDevice,
    deleteDevice
  })
)(({ loading, fetchDevices, devices, addDevice, approveDevice,deleteDevice }) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [values, setValues] = useState({
    network: "",
    phoneNumber: "",
    country: "",
    imei: "",
    email: "",
    name: "",
  });
  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addDevice(values);

    handleCloseModal();
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  return (
    <DevicesComponent
      loading={loading}
      showModal={showModal}
      handleShowModal={handleShowModal}
      handleCloseModal={handleCloseModal}
      onChange={onChange}
      values={values}
      devices={devices}
      onSubmit={onSubmit}
      approveDevice={approveDevice}
      deleteDevice={deleteDevice}
    />
  );
});

export default DevicesContainer;
