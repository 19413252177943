import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { Fragment } from "react";

const Loader = ({ show }) => {
  return (
    <Backdrop
      style={{
        mt: "0px !important",
        height: "100vh",
        width: "100vw",
        zIndex: 5000,
      }}
      open={show}
    >
     
      <CircularProgress color="init" />
    </Backdrop>
  );
};

export default Loader;
