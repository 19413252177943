import React, { useEffect, useState } from "react";
import UsersComponent from "./users-component";

import { createNotifications, fetchUsers } from "./users-saga";
import { compose } from "ramda";
import { connect } from "react-redux";
import { getLoading, getUsers } from "./users-reducer";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constant";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  users: getUsers(state),
});

const UsersContainer = compose(
  connect(mapStateToProps, {
    fetchUsers,
    createNotifications,
  })
)(({ loading, fetchUsers, error, users, createNotifications }) => {
  const history = useHistory();
  const handleRowClick = (_, rowData) => {
    history.push(`/user/${rowData.id}`);
  };
  const [filters] = useState(["All", "Approved", "Pending", "No ID"]);
  const [filterTerm, setFilterTerm] = useState("all");
  const [userList, setUserList] = useState(users);
  const [showModal, setShowModal] = useState(false);
  const [tableType, setTableType] = useState("Users");
  const tableTypes = ["Users", "Waitlist Users", "Balances"];
  const [wailist, setWaitlist] = useState([]);
  const [balances, setBalances] = useState([]);
  const handleSelectTableType = (type) => {
    setTableType(type);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [values, setValues] = useState({ message: "", title: "" });
  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const allUsers = users;
    let filtered = [];
    if (filterTerm === "all") {
      filtered = allUsers;
    }
    if (filterTerm === "approved") {
      filtered = allUsers.filter((f) => f.is_id_set && f.is_id_verified);
    }
    if (filterTerm === "pending") {
      filtered = allUsers.filter((f) => f.is_id_set && !f.is_id_verified);
    }
    if (filterTerm === "no id") {
      filtered = allUsers.filter((f) => !f.is_id_set && !f.is_id_verified);
    }

    setUserList(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTerm]);
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleClickFilter = (term) => {
    setFilterTerm(term.toLowerCase());
  };
  // console.log(users)
  const onSubmitCreateNotification = (e) => {
    e.preventDefault();
    createNotifications(values);

    handleCloseModal();
  };
  const fetchWaitlist = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/auth/getallwaitlist`);

      setWaitlist(res.data.waitlist);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBalances = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/allbalances/`);
      // console.log(res.data);
      let arr = [];
      res.data.data.forEach((element) => {
        let obj = {
          username: element.username,
          phone: element.phone,
          country: element.country,
          ghs: element.wallet
            ?.find((i) => i?.currency === "GHS")
            ?.balance.toFixed(2),
          usd: element.wallet
            ?.find((i) => i?.currency === "USD")
            ?.balance.toFixed(2),
          ngn: element.wallet
            ?.find((i) => i?.currency === "NGN")
            ?.balance.toFixed(2),
        };

        arr.push(obj);
      });

      setBalances(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchWaitlist();
    // fetchBalances();
  }, []);

  return (
    <UsersComponent
      handleRowClick={handleRowClick}
      users={userList}
      loading={loading}
      filters={filters}
      handleClickFilter={handleClickFilter}
      filterTerm={filterTerm}
      showModal={showModal}
      handleShowModal={handleShowModal}
      handleCloseModal={handleCloseModal}
      onChange={onChange}
      values={values}
      onSubmitCreateNotification={onSubmitCreateNotification}
      fetchUsers={fetchUsers}
      tableTypes={tableTypes}
      tableType={tableType}
      handleSelectTableType={handleSelectTableType}
      wailist={wailist}
      balances={balances}
    />
  );
});

export default UsersContainer;
