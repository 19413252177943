import React from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

function AddDeviceModal({ onSubmit, onChange, loading, values, onClose }) {
  return (
    <Form onSubmit={onSubmit} style={{ overFlowY: "scroll" }}>
      <h2>Add Device</h2>
      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Owner Name
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="name"
          required
          type="text"
         
          placeholder="Owner name"
        />
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Phone number
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="phoneNumber"
          required
          type="text"
         
          placeholder="Phone number"
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Country
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="country"
          required
          type="select"
          placeholder="message"
        >
           <option>---select country---</option>
           <option value={"Ghana"}>Ghana</option>
           <option value={"Nigeria"}>Nigeria</option>
        </Input>
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Device ID
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="imei"
          required
          type="text"
         
          placeholder="Device ID"
        />
      </FormGroup>

      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Network
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="network"
          required
          type="text"
         
          placeholder="Network"
        />
      </FormGroup>
      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          Email
        </Label>
        <Input
          className="form-control"
          onChange={onChange}
          // value={values.messageExample}
          name="email"
          required
          type="text"
          rows="5"
          placeholder="Email"
        />
      </FormGroup>

      <Button color="danger" onClick={onClose}>
        Close
      </Button>
      <Button disabled={loading} color="primary" className="ml-4" type="submit">
        {!loading ? "Submit" : "Submiting"}
      </Button>
    </Form>
  );
}

export default AddDeviceModal;
