import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./route";
import App from "./features/app";
import Login from "./features/authentication/login-container";
import ConfigDB from "./data/customizer/config";
// import useAuthListener from "./hooks/use-auth.js";
import { history } from "./helpers/browser";
import { getAuth } from "./features/authentication/authentication-reducer";
import { useSelector } from "react-redux";
import ErrorBoundary from "./components/errorBoundary/errorBoundary";
import * as Sentry from "@sentry/react";
function Routes() {
  const user = useSelector(getAuth);

  const [anim, setAnim] = useState("");
  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();

  useEffect(() => {
    setAnim(animation);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
        {user !== null ? (
          <App>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              render={() => {
                return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />;
              }}
            />
            <Sentry.ErrorBoundary
              fallback={({ resetError }) => (
                <ErrorBoundary
                  onClick={() => {
                    window.location.reload();
                    resetError();
                  }}
                />
              )}
            >
              <TransitionGroup>
                {routes.map(({ path, Component }) => (
                  <Route
                    key={path}
                    exact
                    path={`${process.env.PUBLIC_URL}${path}`}
                  >
                    {({ match }) => (
                      <CSSTransition
                        in={match != null}
                        timeout={100}
                        classNames={anim}
                        unmountOnExit
                      >
                        <div>
                          <Component />
                        </div>
                      </CSSTransition>
                    )}
                  </Route>
                ))}
              </TransitionGroup>
            </Sentry.ErrorBoundary>
          </App>
        ) : (
          <Redirect to={`${process.env.PUBLIC_URL}/login`} />
        )}
      </Switch>
    </Router>
  );
}

export default Routes;
