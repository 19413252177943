import React from "react";
import { forwardRef } from "react";
import MaterialTable, { MTableToolbar, Expo } from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Chip, createTheme, MuiThemeProvider } from "@material-ui/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { AtcTransactionsColumns } from "./atc-analytics-data";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  AddDevice: forwardRef((props, ref) => (
    <button
      style={{ textTransform: "none" }}
      className="btn btn-primary ml-4"
      {...props}
      ref={ref}
    >
      {" "}
      Add ATC Device
    </button>
  )),
  Approve: forwardRef((props, ref) => (
    <button
      style={{ textTransform: "none" }}
      className="btn btn-primary btn-sm"
      {...props}
      ref={ref}
    >
      {" "}
      Approve
    </button>
  )),

  //   Check: forwardRef((props, ref) => <CheckBox {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function AtcAnalyticsTable({
  data,
  
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#274ffe",
      },
      secondary: {
        main: "#26facb",
      },
    },
  });

  const getPageSize = (data) => {
    const length = data.length;
    const pageSizes = [20, 50, 100, 500, 800, data.length];
    let sizes = [5];
    for (let i = 0; i < pageSizes.length; i++) {
      sizes.push(pageSizes[i]);
    }
    return sizes;
  };
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        components={
          {
            //   Action: (props) => (
            //     <button
            //       onClick={(event) => props.action.onClick(event, props.data)}
            //       style={{ textTransform: "none" }}
            //       className="btn btn-primary ml-4"
            //     >
            //       Add ATC Device
            //     </button>
            //   ),
          }
        }
        data={data}
        icons={tableIcons}
        columns={AtcTransactionsColumns}
        title={<p className="font-bold">{`${data?.length} devices`}</p>}
        options={{
          search: true,
          selection: false,
          pageSize: Math.min(5, 25),
          columnsButton: true,
          actionsColumnIndex: -1,
          pageSizeOptions: getPageSize(data),
          rowStyle: {
            backgroundColor: "#fff",
          },
          headerStyle: {
            backgroundColor: "#F4F4F4",
            color: "#666666",
            border: 0,
            fontWeight: "600",
          },
          exportMenu: [
            {
              label: "Export PDF",
              //// You can do whatever you wish in this function. We provide the
              //// raw table columns and table data for you to modify, if needed.
              // exportFunc: (cols, datas) => console.log({ cols, datas })
              exportFunc: (cols, datas) => ExportPdf(cols, datas, "dataPDF"),
            },
            {
              label: "Export CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "dataCSV"),
            },
          ],
        }}
        // actions={[
        //   {
        //     icon: tableIcons.Approve,
        //     tooltip: "Approve Device",
        //     isFreeAction: false,
        //     onClick: (row, data) => {
        //       approveDevice({ imei: data.imei });
        //     },
        //   },
        //   {
        //     icon: tableIcons.Delete,
        //     tooltip: "Delete Device",
        //     isFreeAction: false,
        //     onClick: (row, data) => {
        //         deleteDevice({ imei: data.imei });
        //       },
        //   },
        //   {
        //     icon: tableIcons.AddDevice,
        //     tooltip: "Add Device",
        //     isFreeAction: true,
        //     onClick: handleShowModal,
        //   },
        // ]}
        // onRowClick={handleRowClick}
        localization={{
          toolbar: { nRowsSelected: "{0} Users selected" },
        }}
      />
    </MuiThemeProvider>
  );
}

export default AtcAnalyticsTable;
