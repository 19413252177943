import { createSlice } from "@reduxjs/toolkit";
import { createSetter } from "../../helpers/create-setter";
import { pipe, prop } from "ramda";
const slice = "withdrawalRequests";

const initialState = {
  requests: [],
  loading: false,
  error: "",
};

export const {
  actions: { setRequests, setLoading, setError },
  reducer,
} = createSlice({
  initialState,
  name: slice,
  reducers: {
    setRequests: createSetter("requests"),
    setLoading: createSetter("loading"),
    setError: createSetter("error"),
  },
});

const getRequestsState = prop(slice);
const getRequests = pipe(getRequestsState, prop("requests"));
const getLoading = pipe(getRequestsState, prop("loading"));
const getError = pipe(getRequestsState, prop("error"));

export { getRequestsState, getLoading, getRequests, getError, slice };
