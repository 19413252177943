import React, { Fragment } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import ModalBox from "../../components/modal/modal";
import Breadcrumb from "../../layout/breadcrumb";
import Loader from "../../layout/loader";
import BalancesTable from "./balances-table";
function BalancesComponent({ balances, loading }) {
  return (
    <Fragment>
      <Breadcrumb parent="Balances" title="All Balances" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <BalancesTable data={balances} />
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </Fragment>
  );
}

export default BalancesComponent;
