import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";
import CountUp from "react-countup";
import AtcAnalyticsTable from "./atc-analytics-table";
function AtcAnalyticsComponent({ profit, totalAtc, transactions }) {
  return (
    <Fragment>
      <Breadcrumb parent="Atc Analytics" title="Analytics " />
      <Container fluid={true}>
        <Row>
          <Col sm="3" className="box-col-6">
            <Card className="o-hidden">
              <CardBody>
                <div className="ecommerce-widgets media">
                  <div className="media-body">
                    <p className="f-w-500 font-roboto">
                      MTN Total
                      <span className="badge pill-badge-primary ml-3">
                        {/* {withdrawalRequests.length} */}
                      </span>
                    </p>
                    <h4 className="f-w-500 mb-0 f-26">
                      NGN{" "}
                      <span className="counter">
                        <CountUp end={totalAtc.mtn_amount} />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            {" "}
            <Card className="o-hidden">
              <CardBody>
                <div className="ecommerce-widgets media">
                  <div className="media-body">
                    <p className="f-w-500 font-roboto">
                      Airtel Total
                      <span className="badge pill-badge-primary ml-3">
                        {/* {withdrawalRequests.length} */}
                      </span>
                    </p>
                    <h4 className="f-w-500 mb-0 f-26">
                      NGN{" "}
                      <span className="counter">
                        <CountUp end={totalAtc.airtel_amount} />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            {" "}
            <Card className="o-hidden">
              <CardBody>
                <div className="ecommerce-widgets media">
                  <div className="media-body">
                    <p className="f-w-500 font-roboto">
                      MTN Total Profit
                      <span className="badge pill-badge-primary ml-3">
                        {/* {withdrawalRequests.length} */}
                      </span>
                    </p>
                    <h4 className="f-w-500 mb-0 f-26">
                      NGN{" "}
                      <span className="counter">
                        <CountUp end={profit.mtn_profit} />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="o-hidden">
              <CardBody>
                <div className="ecommerce-widgets media">
                  <div className="media-body">
                    <p className="f-w-500 font-roboto">
                      Airtel Total Profit
                      <span className="badge pill-badge-primary ml-3">
                        {/* {withdrawalRequests.length} */}
                      </span>
                    </p>
                    <h4 className="f-w-500 mb-0 f-26">
                      NGN{" "}
                      <span className="counter">
                        <CountUp end={profit.airtel_profit} />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <AtcAnalyticsTable data={transactions} />
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AtcAnalyticsComponent;
