import React from "react";
import WithdrawalRequestsComponent from "./withdrawal-requests-component";
import { getLoading, getRequests } from "./withdrawal-requests-reducer";
import { compose } from "ramda";
import { connect } from "react-redux";
import { approveRequest, declineRequest, fetchRequests } from "./withdrawal-requests-saga";
import { useEffect } from "react";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  requests: getRequests(state),
});

const WithdrawalRequestsContainer = compose(
  connect(mapStateToProps, {
    fetchRequests,
    approveRequest,
    declineRequest
  })
)(({ loading, requests, fetchRequests, approveRequest,declineRequest }) => {
  const handleApproveRequest = (id) => {
    approveRequest({ id });
  };
  const handleDeclineRequest = (id) => {
    declineRequest({ id });
  };
  console.log(requests)
  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <WithdrawalRequestsComponent
      handleApproveRequest={handleApproveRequest}
      loading={loading}
      requests={requests}
      fetchRequests={fetchRequests}
      handleDeclineRequest={handleDeclineRequest}
    />
  );
});

export default WithdrawalRequestsContainer;
