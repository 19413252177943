import toast from "react-hot-toast";
import { setError, setUsers, setLoading, setDevices } from "./atc-reducer";
import { getAuth } from "../authentication/authentication-reducer";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { BASE_URL } from "../../constant";
import axios from "axios";
import _ from "lodash";
import { history } from "../../helpers/browser";
import { API } from "aws-amplify";
function* handleFetchDevices() {
  try {
    yield put(setLoading(true));

    const apiName = "atcApi";
    const path = `/list-devices`;
    const devices = yield call([API, "get"], apiName, path);
    yield put(setDevices(devices.data));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error, "here");
    yield call([toast, "error"], "eror");
  }
}

const fetchDevices = (payload) => ({ type: fetchDevices.type, payload });
fetchDevices.type = "ATC/fetchDevices";

function* watchFetchDevices() {
  yield takeEvery(fetchDevices.type, handleFetchDevices);
}

function* handleApproveDevice({ payload}) {
  try {
    yield put(setLoading(true));
    const apiName = "atcApi";
    const path = `/approve-device`;
    const data = {
      body: payload,
    };
    console.log(data)
    yield call([API, "post"], apiName, path, data);
    yield put(fetchDevices());
    yield put(setLoading(false));
    toast.success("Device approved");
    
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    yield call([toast, "error"], "eror");
  }
}

const approveDevice = (payload) => ({
  type: approveDevice.type,
  payload,
});
approveDevice.type = "ATC/approveDevice";

function* watchApproveDevice() {
  yield takeEvery(approveDevice.type, handleApproveDevice);
}
function* handleDeleteDevice({ payload}) {
    try {
      yield put(setLoading(true));
      const apiName = "atcApi";
      const path = `/delete-device`;
      const data = {
        body: payload,
      };
      console.log(data)
      yield call([API, "post"], apiName, path, data);
      yield put(fetchDevices());
      yield put(setLoading(false));
      toast.success("Device deleted");
 
    } catch (error) {
      yield put(setLoading(false));
      yield put(setError(error.message));
      console.log(error.response);
      yield put(setLoading(false));
      yield call([toast, "error"], "eror");
    }
  }
  
  const deleteDevice = (payload) => ({
    type: deleteDevice.type,
    payload,
  });
  deleteDevice.type = "ATC/deleteDevice";
  
  function* watchDeleteDevice() {
    yield takeEvery(deleteDevice.type, handleDeleteDevice);
  }
  

function* handleAddDevice({ payload }) {
  try {
    yield put(setLoading(true));
    const apiName = "atcApi";
    const path = `/add-device`;
    const data = {
      body: payload,
    };
    yield call([API, "post"], apiName, path, data);
    yield put(fetchDevices());
    yield put(setLoading(false));
    toast.success("New Device Added");
    // yield call([history, history?.push], "/users");
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    console.log(error.response);
    yield put(setLoading(false));
    yield call([toast, "error"], "eror");
  }
}
const addDevice = (payload) => ({
  type: addDevice.type,
  payload,
});
addDevice.type = "ATC/addDevice";

function* watchAddDevice() {
  yield takeEvery(addDevice.type, handleAddDevice);
}

export {
  watchFetchDevices,
  fetchDevices,
  watchApproveDevice,
  approveDevice,
  addDevice,
  watchAddDevice,
  deleteDevice,
  watchDeleteDevice
};
