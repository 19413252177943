import { createSlice } from "@reduxjs/toolkit";
import { createSetter } from "../../helpers/create-setter";
import { pipe, prop } from "ramda";
import { clear } from "../../redux/clear";
const slice = "balances";

const initialState = {
  balances: [],
  transactions:[],
  loading: false,
  error: "",
};

export const {
  actions: { setBalances, setLoading, setError,setTransactions },
  reducer,
} = createSlice({
  initialState,
  name: slice,
  reducers: {
    setBalances: createSetter("balances"),
    setLoading: createSetter("loading"),
    setError: createSetter("error"),
    setTransactions:createSetter("transactions")
  },
  extraReducers: {
    [clear.type]: () => initialState,
  },
});

const getBalancesState = prop(slice);
const getBalances = pipe(getBalancesState, prop("balances"));
const getLoading = pipe(getBalancesState, prop("loading"));
const getError = pipe(getBalancesState, prop("error"));
const getTransactions = pipe(getBalancesState, prop("transactions"));

export { getBalancesState, getLoading, getBalances, getError,getTransactions, slice };
