import React, { useEffect, useState } from "react";
import TransactionsComponent from "./transactions-component";

import { fetchTransactions } from "../balances-saga";
import { compose } from "ramda";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
import { getTransactions, getLoading } from "../balances-reducer";
const mapStateToProps = (state) => ({
  loading: getLoading(state),
  transactions: getTransactions(state),
});

const TransactionsContainer = compose(
  connect(mapStateToProps, {
    fetchTransactions,
  })
)(({ loading, transactions, fetchTransactions }) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchTransactions();
  }, []);
  console.log(transactions)

  return (
    <TransactionsComponent
      loading={loading}
      showModal={showModal}
      transactions={transactions}
      handleShowModal={handleShowModal}
      handleCloseModal={handleCloseModal}
    />
  );
});

export default TransactionsContainer;
