import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function ModalBox({ open, content, headerText, toggleOpen }) {
  return (
    <Modal isOpen={open} toggle={toggleOpen}>
      <ModalHeader toggle={toggleOpen}>{headerText}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      {/* <ModalFooter>
               <Button color="secondary" onClick={VaryingContenttwotoggle}>{Close}</Button>
               <Button color="primary" onClick={VaryingContenttwotoggle}>{SendMessage}</Button>
         </ModalFooter> */}
    </Modal>
  );
}

export default ModalBox;
