import toast from "react-hot-toast";
import {
  setError,
  setTransactions,
  setLoading,
  setBalances,
} from "./balances-reducer";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { API } from "aws-amplify";
function* handleFetchBalances() {
  try {
    yield put(setLoading(true));

    const apiName = "reportsApi";
    const path = `/balances`;
    const balances = yield call([API, "get"], apiName, path);
    let all = [];
    balances.data.forEach((element) => {
      let obj = {
        id: element.id,
        username: element.username,
        email: element.email,
        name: element.name,
        country: element.country,
        ghs: element.balances
          ?.find((i) => i?.currency === "GHS")
          ?.balance.toFixed(2),
        usd: element.balances
          ?.find((i) => i?.currency === "USD")
          ?.balance.toFixed(2),
        ngn: element.balances
          ?.find((i) => i?.currency === "NGN")
          ?.balance.toFixed(2),
      };
      all.push(obj);
    });
    yield put(setBalances(all));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    yield put(setError(error.message));
    yield call([toast, "error"], "eror");
  }
}

const fetchBalances = (payload) => ({ type: fetchBalances.type, payload });
fetchBalances.type = "BALANCES/fetchBalances";

function* watchFetchBalances() {
  yield takeEvery(fetchBalances.type, handleFetchBalances);
}

function* handleFetchTransactions() {
  try {
    yield put(setLoading(true));

    const apiName = "reportsApi";
    const path = `/transactions`;
    const { data,wallets } = yield call([API, "get"], apiName, path);
// console.log(wallets,"here")
    yield put(setTransactions(data));
    yield put(setLoading(false));
  } catch (error) {
    console.log(error)
    yield put(setLoading(false));
    yield put(setError(error.message));
    yield call([toast, "error"], "eror");
  }
}

const fetchTransactions = (payload) => ({
  type: fetchTransactions.type,
  payload,
});
fetchTransactions.type = "BALANCES/fetchTransactions";

function* watchFetchTransactions() {
  yield takeEvery(fetchTransactions.type, handleFetchTransactions);
}

export {
  watchFetchBalances,
  fetchBalances,
  watchFetchTransactions,
  fetchTransactions,
};
